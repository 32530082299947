div.form-template {
    box-shadow: 0px 0px 5px;
    max-width: 650px;
    margin: auto;
}

div.form-template.form-consult-problem {
    width: 100%;
    max-width: none;
}

div.form-template, div.form-template .label-form {
    border-radius: 15px;
}

div.form-template .alert-form {
    margin: 10px;
}

div.form-template .label-form {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 20px;
    background-color: $ternary;
    color: white;
}

div.form-template .body-form {
    padding: 20px 50px;
}

@include media-breakpoint-down(md) {
    div.form-template .body-form {
        padding: 20px 5px;
    }
}

div.form-template .btn {
    display: inline-block;
}

div.form-template .btn-form {
    text-align: center;
}