header {
  box-shadow: 0 2px 5px gray;
}

#navbar {
  background-color: $ternary;
  margin: 0 auto;
  width: 100%;

  .navbar {
    margin: 0 auto;
  }
}

div#navbarPrincipal li.nav-item {
  height: 50px;

  & > a {
    height: 100%;
    line-height: 34px;
  }

  svg {
    font-size: 16px;
  }

  a,
  button {
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 2px;

    &:not(#admin, #logout, #console, .active, :hover) {
      color: #ccc;
    }
  }

  a.active,
  button.active,
  button:active {
    background-color: #303030;
    color: white;
  }

  a:hover,
  button:hover {
    background-color: #303030;
    color: white;
  }
}

nav .dropdown-menu a#admin,
nav .dropdown-menu button#logout,
nav .dropdown-menu a#console {
  color: $secondary;
}

div#navbarPrincipal li.nav-item .dropdown-menu.no-dropdown-hover li {
  & > a,
  & > button {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

div#navbarPrincipal li.nav-item:hover .dropdown-menu:not(.no-dropdown-hover) {
  display: block;
}

div#navbarPrincipal li.nav-item:hover .dropdown-menu:not(.no-dropdown-hover).dropdown-menu-end {
  right: 0;
  left: auto;
}

div#navbarPrincipal li.nav-item .dropdown-menu:not(.no-dropdown-hover) {
  border-radius: 0;

  a,
  p {
    padding-bottom: 12px;
    padding-top: 12px;
  }
}

div#navbarPrincipal ul.dropdown-menu {
  background-color: $ternary;
  color: white;

  &:not(.no-dropdown-hover) {
    margin-top: -1px;
  }
}
