.profil-image {
    border: 1px black solid;
    border-radius: 50%;
    width: 100%;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .profil-image {
        width: 50%;
        padding-top: 50%;
    }

    #main-profil {
        margin-bottom: 10px;
    }
}